<!-- Modal to confirm overwrite of previously uploaded file -->
<template>
  <v-dialog justify="center" :value="value" persistent max-width="800px">
    <v-card color=#fafafa>
      <v-toolbar flat dark class="main">
          <v-toolbar-title>Warning</v-toolbar-title>
          <v-spacer />
          <v-btn
            text
            color="grey darken-2"
            @click="$emit('close')"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <section class="ma-0 pa-6 warningModal">
        <v-row justify="center">
            <div v-if="partiesGap.length > 0" class='pa-4'>
              A file named "{{documentFileName}}" with this category and subcategory already exists. Uploading will overwrite the existing file. The new file is missing the following parties:
              <v-data-table
                height="200px"
                :headers="headers"
                :items.sync="partiesGap"
                :items-per-page="100"
                item-key="party_id"
                fixed-header
                hide-default-footer
                dense
                class="partyGapTable"
              >
              </v-data-table>
              <br />
              Are you sure you want to continue?
            </div>
            <span v-else class="pa-4">
              A file named "{{documentFileName}}" with this category and subcategory already exists. Uploading will overwrite the existing file. Are you sure you want to continue?
            </span>
        </v-row>
        <v-row justify="center" class="pa-4 align-end ma-auto">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('reject')" outlined>
            No
          </v-btn>
          <v-btn color="blue" height="40" class="white--text ml-2" 
            @click="$emit('confirm')">Yes
          </v-btn>
        </v-row>
      
      </section>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmFileOverwriteModal',
  props: ["value", "documentFileName", "partiesGap"],

  data() {
    return {
      headers: [
        { text: 'Name', value: 'party_name', sortable: false },
        { text: 'Type', value: 'party_type', sortable: false }
      ]
    }
  }
}
</script>
<style>
.warningModal {
  min-height: 250px;
  display: flex;
  flex-direction: column;
}

.partyGapTable.v-data-table {
  background: #fafafa;
;
}
</style>